<template>
  <div class="Base-Div">
    <div class="Feeds-macro-container">
      <div
        class="Feeds-user-container"
        v-for="(video, index) in userVideos"
        :key="index"
        :data-embed-url="video.embedURL"
        :data-index="index"
        :href="`#${index}`"
      >
        <div class="user-info">
          <img :src="video.profilePicture" alt="User Picture" class="custom-user-round-picture" />
          <a :href="`/user/${encodeURIComponent(video.profileName)}`" class="custom-user-profile-name">{{ video.profileName }}</a>
          <!--<a :href="`http://www.vu1cano2k/user/${encodeURIComponent(video.profileName)}`" class="custom-user-profile-name">{{ video.profileName }}</a>-->
        </div>
        <div class="video-wrapper">
          <div class="video-title-a">
          <h3>{{ video.videoTitle }} </h3>
        </div>
          <div class="video-loader" v-if="isLoadingVideo[index]">
            <div class="circle-dots-loader">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
            <span>Loading video...</span>
          </div>
          <div v-if="embeddedVideos[index]" class="iframe-wrapper">
            <iframe 
              :src="embeddedVideos[index]"
              width="100%" 
              height="100%" 
              frameborder="0" 
              allowfullscreen
              sandbox="allow-scripts allow-same-origin"
              @error="onEmbedError(index)"
              @load="onEmbedLoad(index)"
            ></iframe>
            <div v-if="embedErrors[index]" class="embed-error-message">
              Erro ao carregar o vídeo. Por favor, verifique a fonte ou abra em uma nova janela.
            </div>
          </div>

          <video v-else
            :data-src="video.previewURL" 
            class="responsive-video preview"
            preload="metadata"
            muted
            @contextmenu.prevent
            loop
            @click="togglePlayPause(index)"
            @dblclick="DeixaEmTelaCheia(index)"
            @timeupdate="updateProgress(index)"
            
          ></video>
          <div v-if="!embeddedVideos[index]">
              <button @click="toggleMute(index)" class="mute-button">
                <i :class="isMuted[index] ? 'fas fa-volume-mute' : 'fas fa-volume-up'"></i>
              </button>

                <!-- Botão de full-screen -->
              <button @click="DeixaEmTelaCheia(index)" class="Cfullscreen-button">
                <i :class="isFullscreen[index] ? 'fas fa-compress' : 'fas fa-expand'"></i>
              </button>     
              <div class="custom-progress-bar" @click="seekVideo($event, index)">
                <div class="custom-progress-handle" 
                @mousedown="startDrag($event, index)" 
                @touchstart="startDrag($event, index)">
                </div>
              </div>
            </div>          
        </div>
        <div class="buttons-and-tags">
          <div class="feeds-interaction-buttons">
            <!-- 
            <button class="custom-thumbs-up" @click="toggleThumbsUp(index)">
              <i :class="{'fas fa-thumbs-up': true, active: isThumbsUpActive(index)}"></i>
            </button>
            <button class="custom-thumbs-down" @click="toggleThumbsDown(index)">
              <i :class="{'fas fa-thumbs-down': true}"></i>
            </button>   
            -->       
          </div>
          <div class="watch-full-clip-container" @click="replaceWithEmbed(index)">
            <span class="watch-full-clip-text">Watch Full Clip</span>
          </div>
          <div class="custom-Hash-container">
            <div class="custom-Hash-border">
              <div class="custom-hash-item" v-for="(hashtag, idx) in video.hashtags" :key="idx">
                <span>{{ hashtag }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'; // Import axios for making HTTP requests
import { debounce } from 'lodash';
import { ref, onMounted, nextTick, onUnmounted } from 'vue'; // Import Vue composition API functions
import { customPlayerFunctions } from '../composables/customPlayer.js';


const BASE_URL = 'https://strapi.vulcano2k.com'; // Base URL for API requests



export default {
  name: 'UserProfile', // Component name
  setup() {

    const userVideos = ref([]); // Inicialize userVideos primeiro
  

    // Define reactive variables using Vue's ref function 
    const thumbsUpCounts = ref([]); // Array to store thumbs up counts for each video
    const videoRef = ref(null); // Reference to video element (currently not used)
    const currentPage = ref(1);
    const pageSize = ref(20);
    const isLoading = ref(false);
    let observer; // Variable for IntersectionObserver for preview videos
    let iframeObserver; // Variable for IntersectionObserver for embedded videos (iframes)   

    const isLoadingVideo = ref([]);

    const embeddedVideos = ref({}); // Armazena os vídeos embedidos
    const embedErrors = ref({});

    const bufferVideos = ref([]);



     

    // Lifecycle hook that runs when the component is mounted
    onMounted(() => {      
      fetchUserData(); // Fetch user data when the component is mounted
      const debouncedScroll = debounce(InfinityScroll, 800)
      window.addEventListener('scroll', debouncedScroll);
      if (observer) observer.disconnect();

      
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', InfinityScroll);
      if (observer) observer.disconnect(); // Adicione esta linha
    });

    const replaceWithEmbed = (index) => {
      // Exibe o vídeo embedado
      embeddedVideos.value[index] = userVideos.value[index].embedURL;
      console.log("Embed carregado:", embeddedVideos.value[index]);

      nextTick(() => {
        setupEmbedObserver();
      });
      
      // Após a atualização do DOM, configura o observer para monitorar o embed

    };

    
    // Para o double click
    const togglePlayPause = (index) => {
      const videoElements = document.querySelectorAll('.responsive-video.preview');
      const videoEl = videoElements[index];

      if (videoEl.paused) {
        videoEl.play();
      } else {
        videoEl.pause();
      }  
    };   

    
    // Function to fetch user data from the API
    const fetchUserData = async () => {
      console.log("Calling FetchUserData")
        try {
          isLoading.value = true;

          // Pausa todos os vídeos antes de carregar novos
          document.querySelectorAll('.responsive-video.preview').forEach(video => {
            video.pause();
            video.currentTime = 0;
          });

          const response = await axios.get(
            `${BASE_URL}/api/user-video-embeds`, {
              params: {
                'pagination[page]': currentPage.value,
                'pagination[pageSize]': pageSize.value,
                'sort': ['createdAt:desc'], 
                populate: {
                  user_profile: {
                    populate: {
                      ProfilePicture: {
                        fields: ['url']
                      }
                    }
                  },
                  hashtags: true,
                  VideoPreview: true,                  
                },
                fields: ['createdAt', 'embedURL', 'VideoTitle'] 
              }
            }
          );

          const data = response.data.data;

          console.log(response.data.data)

          // Process the data and store it in userVideos.value
          const newVideos = data.map((item) => ({
            profileName: getProfileName(item),
            profilePicture: getProfilePicture(item),
            userId: getUserId(item),
            hashtags: getHashtags(item),
            previewURL: getVideoPreviewURL(item),
            embedURL: item.attributes.embedURL,

            videoTitle: item.attributes.VideoTitle || 'Untitled',
            createdAt: new Date(item.attributes.createdAt),
          })).sort(() => Math.random() - 0.5);

          isLoadingVideo.value = userVideos.value.map(() => false);

          // Após criar newVideos (20 itens)
          const newVideosToShow = newVideos.slice(0, 10);
          const newVideosBuffer = newVideos.slice(10);

          // Adiciona os primeiros 10 ao array renderizado
          userVideos.value.push(...newVideosToShow);

          // Guarda os 10 restantes para carregamento futuro
          bufferVideos.value.push(...newVideosBuffer);


          isMuted.value.push(...new Array(newVideosToShow.length).fill(true));
          thumbsUpCounts.value.push(...new Array(newVideosToShow.length).fill(0));

          await nextTick(); // Wait for DOM to update

          setupIntersectionObserver(); // Stop/Start Preview
          

        } catch (error) {
          console.error('Error fetching user data:', error); // Log error if fetching data fails
        } finally {
          isLoading.value = false;
        }
    };

    // Função para incrementar a página e chamar fetchUserData
    const loadNextPage = () => {
      currentPage.value++;
      fetchUserData();
    };


    const { 
      isMuted, 
      toggleMute, 
      updateProgress, 
      seekVideo, 
      startDrag, 
      stopDrag, 
      handleDrag,
      isFullscreen,
      DeixaEmTelaCheia
    } = customPlayerFunctions(fetchUserData, loadNextPage);

    // Function to get video preview URL
    const getVideoPreviewURL = (item) => {
      const previewUrl = item.attributes.VideoPreview.data[0].attributes.url;
      return `${BASE_URL}${previewUrl}`;
    };



    // Function to get profile name from item data
    const getProfileName = (item) => {
      const userProfile = item.attributes?.user_profile?.data?.attributes || 'W/O userProfile associated';
      return userProfile.ProfileName;
    };

    // Function to get profile picture URL
    const getProfilePicture = (item) => {
      const userProfile = item.attributes?.user_profile?.data?.attributes;
      if (userProfile && userProfile.ProfilePicture?.data?.attributes?.url) {
        return `${BASE_URL}${userProfile.ProfilePicture.data.attributes.url}`;
      }
      return ''; // Return an empty string if no profile picture URL is available
    };

    // Function to get user ID from item data
    const getUserId = (item) => item.attributes?.user_profile?.data?.id;

    // Function to get hashtags from item data
    const getHashtags = (item) =>
      item.attributes?.hashtags?.data.map((hashtag) => hashtag.attributes.HashCategorie) || [];

    // Function to toggle thumbs up for a video
    const toggleThumbsUp = (index) => {
      // Toggle between 0 and 1
      thumbsUpCounts.value[index] = thumbsUpCounts.value[index] === 0 ? 1 : 0;
    };

    // Function to check if thumbs up is active for a video
    const isThumbsUpActive = (index) => {
      return thumbsUpCounts.value[index] === 1;
    };

    // Function to handle thumbs down action (remove video from the list)
    const toggleThumbsDown = (index) => {
      const videoContainer = document.querySelectorAll('.Feeds-user-container')[index];

      if (videoContainer) {
        setTimeout(() => {
          // Remove the video data from the arrays
          userVideos.value.splice(index, 1);
          thumbsUpCounts.value.splice(index, 1);

          // Wait for the DOM to update
          nextTick(() => {
            // Disconnect the observers
            if (observer) observer.disconnect();
            if (iframeObserver) iframeObserver.disconnect();

            // Re-observe the current videos
            setupIntersectionObserver();
          });
        }, 100);
      }
    };

    // Carrega mais dados quando o usuário chega próximo ao final da página.
    const InfinityScroll = () => {      
      const { scrollTop, scrollHeight, clientHeight } = document.body;

      if (scrollTop + clientHeight >= scrollHeight - 100 && !isLoading.value) {
        if (bufferVideos.value.length >= 10) {
          const nextBatch = bufferVideos.value.splice(0, 10);
          userVideos.value.push(...nextBatch);

            // Após atualizar o DOM com os novos vídeos, reconfigura o observer:
          nextTick(() => {
            setupIntersectionObserver();
          });
          
        } else {
          // Se não houver vídeos suficientes no buffer, busque mais
          currentPage.value++;
          fetchUserData();
        }
      }
    };
    
    // Function to set up Intersection Observers
    const setupIntersectionObserver = () => {
      //Verifica se já existe um observer criado. Se sim, desconecta-o de todos os elementos observados para 
      // evitar múltiplas instâncias ou observações duplicadas.
      if (observer) observer.disconnect();

      // Cria um WeakMap que associará cada elemento de vídeo a um timer (setTimeout). Dessa forma, se o vídeo sair da tela, 
      // o timer pode ser cancelado para evitar que o carregamento seja iniciado indevidamente.
      const videoTimers = new WeakMap();

      //Cria um novo observer com duas partes:
      //Callback: Uma função que receberá um array de "entries" (cada uma representando um elemento observado e suas informações de visibilidade).
      observer = new IntersectionObserver(
        (entries) => {
          //Para cada elemento (entry) que o observer acompanha, o código realizará uma verificação e 
          // ações específicas conforme a visibilidade do elemento.
          entries.forEach((entry) => {
            //*- video: Armazena o elemento observado.
            //container: Procura o ancestral mais próximo com a classe .Feeds-user-container, que é o “bloco” que envolve o vídeo e outras informações (como o índice).
            //Se o container não for encontrado, a função retorna.
            //currentIndex: Obtém o valor do atributo data-index do container, convertendo-o para inteiro; esse índice é usado para atualizar estados reativos, como o loader do vídeo.*
            const video = entry.target;
            const container = entry.target.closest('.Feeds-user-container');
            if (!container) return;
            const currentIndex = parseInt(container.dataset.index, 10);

            //Se nao for visível, pausa o vídeo e cancela o timer de carregamento.
            if (!entry.isIntersecting) {
              if (!video.hasAttribute('data-loaded')) {
              
                if (videoTimers.has(video)) {
                  clearTimeout(videoTimers.get(video));
                  videoTimers.delete(video);
                }

                
                video.pause();
                video.currentTime = 0;
                //video.removeAttribute('src');
                isLoadingVideo.value[currentIndex] = false;
                return;
              }
            }

            // Mostrar loader antes de iniciar o timer
           // isLoadingVideo.value[currentIndex] = true;


            // Iniciar timer para carregamento após 800ms de permanência
            const timer = setTimeout(() => {
              if (!video.src && video.dataset.src) {
                video.src = video.dataset.src;
                video.load();


                // Evento quando o vídeo começa a tocar
                video.addEventListener('playing', () => {
                  isLoadingVideo.value[currentIndex] = false;
                  video.setAttribute('data-loaded', 'true');
                  
                });

                // Tratar erros
                video.addEventListener('error', () => {
                  isLoadingVideo.value[currentIndex] = false;
                  console.error('Erro ao carregar vídeo');
                });

                
                video.play().catch(e => {
                  if (e.name !== 'AbortError') console.error('Play error:', e)
                });
              }

              // Pré-carregar próximo vídeo apenas após confirmação de permanência
              const nextVideo = document.querySelector(
                `[data-index="${currentIndex + 1}"] .responsive-video.preview`
              );
              if (nextVideo && !nextVideo.src && nextVideo.dataset.src) {
                // Usar o mesmo observer para o próximo vídeo
                observer.observe(nextVideo);
              }
            }, 600); // Tempo aumentado para 800ms

            videoTimers.set(video, timer);
          });
        },
        {      
        //threshold: 0.1: A callback será disparada quando 10% do elemento estiver visível.
        //rootMargin: Define uma margem extra (no caso, 100px no fundo) para “antecipar” a visibilidade do elemento.*
          threshold: 0.1,
          rootMargin: "0px 0px 100px 0px"
        }
      );

      document.querySelectorAll('.responsive-video.preview').forEach(v => observer.observe(v));
    };

    const replaceWithPreview = (index) => {
  // Remove o embed para voltar ao preview
  embeddedVideos.value[index] = null;
  console.log("Embed removido:", index);
  
  // Após a atualização do DOM, reativa o observer para os vídeos de preview
  nextTick(() => {
    setupIntersectionObserver();
  });
};

    let embedObserver;

    const setupEmbedObserver = () => {
      // Caso já exista um observer, desconecta para evitar duplicação

      console.log("Iframes encontrados:", document.querySelectorAll('.iframe-wrapper iframe').length);

      if (embedObserver) {
        embedObserver.disconnect();
      }

      // Cria um novo observer para os embeds
      embedObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            // Encontre o container pai que tem o atributo data-index
            const container = entry.target.closest('.Feeds-user-container');

            if (container) {
              const index = parseInt(container.dataset.index, 10);
              replaceWithPreview(index);
            }
          }
        });
      }, {
        threshold: 0.1,
        rootMargin: "0px 0px -100px 0px"
      });

      // Observe todos os iframes que estão sendo renderizados
      document.querySelectorAll('.iframe-wrapper iframe').forEach((iframe) => {
        embedObserver.observe(iframe);
      });
    };


        // Função acionada quando o iframe gera erro
    const onEmbedError = (index) => {
      embedErrors.value[index] = true;
    };

    // Função acionada quando o iframe carrega (pode resetar o erro, se necessário)
    const onEmbedLoad = (index) => {
      // Se desejar, pode resetar a flag de erro caso o embed carregue corretamente
      embedErrors.value[index] = false;
    };
          
    // Return the reactive variables and functions to be used in the template
    return {
      userVideos,
      thumbsUpCounts,
      toggleThumbsUp,
      toggleThumbsDown,
      isThumbsUpActive,      
      videoRef,
      togglePlayPause,        
      toggleMute,
      isMuted,
      // From customPlayer
      isFullscreen,
      DeixaEmTelaCheia,
      updateProgress,
      seekVideo,
      startDrag,
      stopDrag,
      handleDrag,
      isLoadingVideo,
      embeddedVideos,
      replaceWithEmbed,
      embedErrors,
      onEmbedError,
      onEmbedLoad,

    };
  },
};
</script>

<style>
  /* Um reset CSS básico */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
 
  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .main-content {
    padding: 0;
    margin: 0; /* Se houver algum margin também */
  }

  .Base-Div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;  
    box-sizing: border-box;
    padding: 5px;
    margin: 0;
  }

  .Feeds-title {
    display: block;
    font-size: 40px;
    align-items: flex-start;
    width: 100%;
    font-family: 'Cinzel', serif;
  }

  .Feeds-macro-container { 
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 10px;
    align-items: center;
    gap: 10px;
    pointer-events: auto; /* Permite interação */    
  }

  .Feeds-user-container {  /*Video + User Profile + Buttons + tags */
    display: flex;  
    width: 100%;  
    min-width: 200px;
    min-height: 350px;    
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    margin-top: 14px;
    margin-bottom: 20px;
    padding: 5px;    
    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
    box-sizing: border-box;
    overflow: visible;
  }

  .user-info {
    justify-content: flex-start;
    display: flex;
    align-items: center;
    margin-bottom: 10px;  
    flex-wrap: nowrap; /* Prevents vertical wrapping */
    width: 100%;
    box-sizing: border-box;
    gap: 10px;
    max-width: 100%;
  }

  .custom-user-round-picture {
    align-items: left;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .custom-user-profile-name {
    font-family: Arial, sans-serif;
    color: #8B0000;
    font-size: 40px;
    text-decoration: none;
  }


  .video-title-a {
    position: absolute;
    top: 0;
    left: 10px;
    width: 100%;
    margin: 0; /* Remove margens externas */
 
    z-index: 2; /* Garante que o título fique acima do vídeo */
    pointer-events: none; /* Permite interação com o vídeo */
    opacity: 0;
    transition: opacity 0.3s ease;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
  }

  .video-title-a h3 {
    pointer-events: auto; /* Permite interação com o título se necessário */
  }

  /* Ajuste opcional para melhor posicionamento */
  .video-title-a h3 {
    top: 10px; /* Ajuste conforme necessário */
    left: 10px;
    font-size: 22px;

    
    max-width: 95%; /* Evita que o título ultrapasse o vídeo */
  }
  

  .video-wrapper:hover .video-title-a{
  opacity: 1;
  }


  .video-wrapper {
    width: 100%; /* Ajuste a largura conforme necessário */
    height: auto; /* Preserva a proporção do vídeo */
    position: relative;
    display: block;
    justify-content: center;
    align-items: center;
    overflow: visible!important;
    z-index: 1;
    
  }


  .video-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  z-index: 2; /* Garante que fica acima do vídeo */
  color: #fff;
  background: rgba(0,0,0,0.7);
  padding: 20px;
  border-radius: 10px;
  pointer-events: none; /* Permite cliques passarem para o vídeo */
  }


  .circle-dots-loader {
  position: relative;
  width: 60px;
  height: 60px;
  margin: 20px auto;
}

.dot {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #8B0000;
  border-radius: 50%;
  animation: circle-dots 1.2s infinite ease-in-out both;
}

/* Posicionamento preciso em círculo */
.dot:nth-child(1) { top: 0; left: 26px; animation-delay: -0.1s; }
.dot:nth-child(2) { top: 7px; right: 7px; animation-delay: -0.2s; }
.dot:nth-child(3) { top: 26px; right: 0; animation-delay: -0.3s; }
.dot:nth-child(4) { bottom: 7px; right: 7px; animation-delay: -0.4s; }
.dot:nth-child(5) { bottom: 0; left: 26px; animation-delay: -0.5s; }
.dot:nth-child(6) { bottom: 7px; left: 7px; animation-delay: -0.6s; }
.dot:nth-child(7) { top: 26px; left: 0; animation-delay: -0.7s; }
.dot:nth-child(8) { top: 7px; left: 7px; animation-delay: -0.8s; }

@keyframes circle-dots {
  0%, 80%, 100% { 
    transform: scale(0.4);
    opacity: 0.5;
  }
  40% {
    transform: scale(1);
    opacity: 1;
  }
}



  .responsive-video.preview {
    position: relative; /* Garante a ordem de empilhamento */
    z-index: 1;
  }

  .responsive-video.preview {
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    display: block;    
    border-radius: 15px;      
  }

  .iframe-wrapper {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* Mantém a proporção 16:9 */
}

.iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px; /* Para deixar igual ao preview, se desejar */
}

.embed-error-message {
  margin-top: 10px;
  padding: 10px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
}

  .mute-button {
    font-size: 20px;
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(0,0,0,0.5);
    border: none;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    z-index: 10; /* para garantir que fique sobre o vídeo */
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .Cfullscreen-button {
    position: absolute;
    bottom: 10px; 
    right: 10px; 
    background: rgba(0, 0, 0, 0.5);
    border: none;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    z-index: 10;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
  }

  .Cfullscreen-button:hover {
    background: rgba(0, 0, 0, 0.8);
  }

  .custom-progress-bar {
  position: absolute;
  bottom: -20px;      /* Alinha na parte inferior do container */
  left: 0;        /* Garante que comece à esquerda */
  width: 100%;
  height: 40px;   /* Ajuste essa altura conforme necessário */
  border-radius: 5px;
  overflow: visible;
  z-index: 9999 !important;
  padding: 0;     /* Remove o padding que pode estar deslocando */
  }

  .custom-progress-handle {
    width: 22px;
    height: 22px;
    background: #ffffff;
    border-radius: 50%;
    position: absolute;
    z-index: 9999;
    top: 50%; /* Centraliza verticalmente */
    transform: translateY(-50%);
    left: 0%; /* Começa no início */
    pointer-events: all; /* Para que o clique passe pela bola */  
  }

  .custom-progress-handle.enlarged {
    transform: translateY(-50%) scale(1.25);
  }  

  .watch-full-clip-container {
    cursor: pointer;
    margin-right: 20px;
    display: flex;
    align-items: center;
  }

  .watch-full-clip-text {
    font-size: 18px;
    color: #8B0000; /* Mesma cor usada no nome do perfil, por exemplo */
    text-decoration: underline;
  }

  .buttons-and-tags{
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content:left;
    align-items: center;
    padding-top: 18px;
  }

  .feeds-interaction-buttons {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 0;  
    max-height: 80%;
  }

  .custom-thumbs-up,
  .custom-thumbs-down {
    display: flex;
    justify-content: flex-start;
    font-size: 18px;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
    border: 2px solid white;
    background-color: transparent;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }

  .custom-thumbs-up-text {
    color: rgb(133, 44, 155);  /* Cor para "Sim" */
    font-weight: bold;
    font-family: 'Arial', sans-serif;
    font-size: 14px;
  }

  .fas.fa-thumbs-up.active {
    color: rgb(0, 124, 182);
  }

  .fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-out;
    max-height: 0;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
    overflow: hidden;
  }

  .custom-Hash-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    width: auto;
    border: 1px solid #ccc; /* Linha fina e cinza */
    box-sizing: border-box; /* Inclui o padding e a borda no cálculo da largura */
    flex-grow: 1;
  }

  .custom-Hash-border {
    display: flex;
    flex-wrap: wrap; /* Permite que os itens ocupem múltiplas linhas */
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 10px;
  }

  .custom-hash-item{
    text-decoration: none;
    background-color: #333;
    color: white;
    border-radius: 5px;
    text-decoration: none;
    font-size: 18px;
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
  }

  .custom-hash-item a {
    color: white;
    text-decoration: none;
  }



/* media-------------------------------------------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 431px) {

  .Feeds-title {
   font-size: 20px;
  }

  .Feeds-user-container {  /*Video + User Profile + Buttons + tags */
    margin-top: 15px;
    padding: 0px;
  }

  .user-info {
   max-width: 100%;
  }

  .custom-user-round-picture {
    width: 45px;
    height: 45px;
  }

  .custom-user-profile-name {
    font-size: 25px;
  }

  .video-title-a h3{
    font-size: 18px;
    max-width: 100%;
  }

  .video-wrapper {
    max-width: 100%;    
    width: 100%;  
  }

  .responsive-video.preview {
    max-width: 100%;
    width: 50px; 
  }

  .buttons-and-tags{
    width: 100%;
    padding-top: 14px;
  }

  .custom-thumbs-up,
  .custom-thumbs-down {
    font-size: 12px;
  }

  .custom-Hash-container {
    max-width: 100%;
  }

  .custom-Hash-container a {
    font-size: 8px;
  }

  .custom-hash-item{
    font-size: 14px;
  }

  .Feeds-macro-container { 
    gap: 0px;
  }
  
  .feeds-interaction-buttons {
    max-width: 100%;
  }

  .custom-hash-item a {
    font-size: 14px;
  }

  .user-info {
    margin-bottom: 5px;  
  }

  .video-wrapper .mute-button {
    font-size: 20px;
    width: 40px;
    height: 40px;
  }

  .Cfullscreen-button {
    font-size: 18px;
    width: 50px;
    height: 50px;
  }

  .video-title-a h3 {

    font-size: 16px;
  }

}
</style>