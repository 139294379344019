<template>
  <footer class="footer">
    <div class="container">
      <p class="footer-text">Be mindful: what you see cannot be unseen.</p>
      <p class="copyright">
        © 2025 Vukcano. All rights reserved.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'MainFooter',
}
</script>

<style scoped>
.footer {
  background-color: #000;           /* fundo negro */
  color: #fff;                      /* texto branco */
  padding: 30px 0;
  text-align: center;
  border-top: 3px solid #8B0000;     /* destaque em vermelho sangue */
  font-family: Arial, sans-serif;
}

.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 15px;
}

.footer-text {
  margin-bottom: 15px;
  font-size: 16px;
}

.footer-menu {
  list-style: none;
  padding: 0;
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.footer-menu li {
  margin: 0 15px;
}

.footer-menu a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
}

.footer-menu a:hover {
  color: #8B0000; /* vermelho sangue no hover */
}

copyright,
p[class="copyright"] {
  font-size: 12px;
  margin-top: 15px;
  color: #aaa;
}
</style>
